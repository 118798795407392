import React from 'react'
const helpers = require('./helpers')

const DateBlock = props => {
  const formattedDateObject = helpers.compareWrittenAndModifiedDates(
    props.writtenOn,
    props.modifiedOn
  )

  return (
    <p className={'publish-on'}>
      {formattedDateObject.isModified === true ? (
        <span>
          {`${formattedDateObject.modifiedPrefix} ${formattedDateObject.modifiedTimeString}`}
        </span>
      ) : (
        <span>
          {`${formattedDateObject.writtenPrefix} ${formattedDateObject.writtenTimeString}`}
        </span>
      )}
    </p>
  )
}

export default DateBlock
