import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import DateBlock from '../components/DateBlock'
import ReturnBack from '../components/ReturnBack'

const NewsTemplate = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  const pageTitle = `Uutinen: ${frontmatter.title}`

  return (
    <Layout>
      <Seo title={pageTitle} />
      <section>
        <div className={'page-block'}>
          <h2 className={'post-type'}>Uutinen</h2>
          <h3 className={'title'}>{frontmatter.title}</h3>
          <DateBlock
            writtenOn={frontmatter.writtenOn}
            modifiedOn={frontmatter.modifiedOn}
          />

          <div className={'post-body'}>
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </div>
        </div>

        <ReturnBack />
      </section>
    </Layout>
  )
}

export default NewsTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        date
        path
        title
        categories
        writtenOn
        modifiedOn
        participants
        speaker
        tags
        location
      }
    }
  }
`
